import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider as MobxProvider } from "mobx-react";
import * as serviceWorker from "./serviceWorker";
import { providers, history } from "./stores/";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import FallBackLoading from "./pages/FallBackLoading";

import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { createUploadLink } from "apollo-upload-client";

const AsyncApp = lazy(() => import("./pages/App"));

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#1487E3"
		},
		text: {
			primary: "#707070"
		}
	}
});

const uploadLink = createUploadLink({
	uri: `https://martinelli-261711.appspot.com/graphql`
});

const authLink = setContext(async (_, { headers }) => {
	const token =
		localStorage.getItem("authToken") ||
		sessionStorage.getItem("authToken");

	return {
		headers: {
			...headers,
			authorization: token ? `${token}` : ""
		}
	};
});

const terminateSession = async () => {
	localStorage.clear();
	sessionStorage.clear();
	window.location.reload();
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		console.error(graphQLErrors);
		const isTerminateSession = graphQLErrors.some(
			e =>
				e.extensions!.type === "TerminateSession" ||
				e.extensions!.type === "AuthError"
		);
		if (isTerminateSession) {
			terminateSession();
		}
	}
	if (networkError) {
		console.error(networkError);
	}
});

const httpLinkHandleError = ApolloLink.from([errorLink, uploadLink]);

const cache = new InMemoryCache();

export const client = new ApolloClient({
	link: ApolloLink.from([authLink.concat(httpLinkHandleError)]),
	cache,
	defaultOptions: {
		mutate: { errorPolicy: "all" },
		query: { errorPolicy: "all" },
		watchQuery: { errorPolicy: "all" }
	}
});

ReactDOM.render(
	<ApolloProvider client={client}>
		<MobxProvider {...providers}>
			<Router history={history}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<Suspense fallback={<FallBackLoading />}>
						<AsyncApp />
					</Suspense>
				</ThemeProvider>
			</Router>
		</MobxProvider>
	</ApolloProvider>,
	document.getElementById("root")
);

serviceWorker.register();
