import { RootStore } from "./";
import { observable, action, toJS } from "mobx";

export interface Customer {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  documentNumber: string;
  phone: string;
}

export class CustomerStore {
  public rootStore: RootStore;

  @observable public checkedCheckboxs: string[] = [];
  @observable public searchText: string = "";
  @observable public customerFormIsOpen: boolean = false;
  @observable public customerToUpdate: Customer | null = null;
  @observable public customerUpdateFormIsOpen: boolean = false;
  @observable public documentFormIsOpen: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  public setCustomerToUpdate = (customer: Customer | null) => {
    this.customerToUpdate = customer;
  };

  @action
  public setCustomerFormIsOpen = (customerFormIsOpen: boolean) => {
    this.customerFormIsOpen = customerFormIsOpen;
  };

  @action
  public setCustomerUpdateFormIsOpen = (customerUpdateFormIsOpen: boolean) => {
    this.customerUpdateFormIsOpen = customerUpdateFormIsOpen;
  };

  @action
  public setDocumentFormIsOpen = (documentFormIsOpen: boolean) => {
    this.documentFormIsOpen = documentFormIsOpen;
  };

  @action
  public setSearchText = (searchText: string) => {
    this.searchText = searchText;
  };

  @action
  public setCheckedCheckboxs = (...args: string[]) => {
    const checkedCheckboxs = toJS(this.checkedCheckboxs);

    for (let arg of args) {
      const idx = checkedCheckboxs.findIndex(e => e === arg);

      if (idx !== -1) {
        checkedCheckboxs.splice(idx, 1);
      } else {
        checkedCheckboxs.push(arg);
      }
    }

    this.checkedCheckboxs = checkedCheckboxs;
  };

  @action
  public emptyCheckedCheckboxs = () => {
    this.checkedCheckboxs = [];
  };
}
