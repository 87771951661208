import React from "react";
import { observer } from "mobx-react-lite";
import { Card } from "../../templates/Card";
import { Container } from "../../templates/Container";
import { Loading } from "../../atoms/Loading";

function FallBackLoadingRaw() {
	return (
		<Container component="main" maxWidth="xs">
			<Card>
				<Loading />
			</Card>
		</Container>
	);
}

export default observer(FallBackLoadingRaw);
