import React from "react";
import { createBrowserHistory } from "history";
import { syncHistoryWithStore, RouterStore } from "mobx-react-router";
import { createContext } from "react";
import { AuthStore } from "./AuthStore";
import { GeneralStore } from "./GeneralStore";
import { CustomerStore } from "./CustomerStore";
import { ChatStore } from "./ChatStore";

export class RootStore {
	public routerStore = new RouterStore();
	public authStore = new AuthStore(this);
	public generalStore = new GeneralStore(this);
	public customerStore = new CustomerStore(this);
	public chatStore = new ChatStore(this);

	public history = syncHistoryWithStore(
		createBrowserHistory(),
		this.routerStore
	);
}

const rootStore = new RootStore();
const { history: varHistory, ...rest } = rootStore;

export const history = varHistory;
export const providers = rest;

export const storeCtx = createContext(rest);

function storesRaw() {
	return () => React.useContext(storeCtx);
}

export const useStores = () => storesRaw()();
