import React, { memo } from "react";
import CircularProgress, {
	CircularProgressProps
} from "@material-ui/core/CircularProgress";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

type Props = CircularProgressProps & { nonMargin?: boolean };

const useStyles = makeStyles(theme => ({
	progress: {
		margin: theme.spacing(2)
	}
}));

function LoadingRaw(props: Props) {
	const { className, nonMargin, ...rest } = props;
	const styles = useStyles();

	return (
		<CircularProgress
			{...rest}
			className={classnames(!nonMargin && styles.progress, className)}
		/>
	);
}

export const Loading = memo(LoadingRaw);
