import { RootStore } from "./";
import { observable, action } from "mobx";

type ToastVariant = "success" | "warning" | "error" | "info";

export class GeneralStore {
	public rootStore: RootStore;

	// Drawer
	@observable public isMobile: boolean = false;

	// Toast
	@observable public isShowToast: boolean = false;
	@observable public toastVariant: ToastVariant = "success";
	@observable public toastMsg: string = "";

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@action
	public handleDrawerToggle = (value: boolean) => (this.isMobile = value);

	@action
	private setShowToast = (value: boolean) => (this.isShowToast = value);

	@action
	private setToastVariant = (value: ToastVariant) =>
		(this.toastVariant = value);

	@action
	private setToastMsg = (value: string) => (this.toastMsg = value);

	@action
	public showToast = (msg: string, variant: ToastVariant) => {
		this.setToastMsg(msg);
		this.setToastVariant(variant);
		this.setShowToast(true);
	};

	@action
	public hiddenToast = () => {
		this.setShowToast(false);
	};
}
