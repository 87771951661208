import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

type Props = React.DetailedHTMLProps<
	React.HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
> & { withoutMargin?: boolean };

const useStyles = makeStyles(theme => ({
	paper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	paperMargin: {
		marginTop: theme.spacing(8)
	}
}));

function CardRaw(props: Props) {
	const styles = useStyles({});
	const { className, withoutMargin, ...rest } = props;

	return (
		<div
			className={classnames(
				styles.paper,
				className,
				!withoutMargin && styles.paperMargin
			)}
			{...rest}
		></div>
	);
}

export const Card = memo(CardRaw);
