import { RootStore } from "./";
import { observable, action } from "mobx";

export class ChatStore {
	public rootStore: RootStore;

	@observable public searchText: string = "";

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@action
	public setSearchText = (searchText: string) => {
		this.searchText = searchText;
	};
}
