import { RootStore } from "./";
import { action } from "mobx";

interface IUser {
	firstName: string;
	lastName: string;
	email: string;
}

export class AuthStore {
	public rootStore: RootStore;

	public user: IUser | null = null;
	public token: string | null = null;
	public isLogged: "logged" | "notLogged" | "pending" = "pending";

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;

		this.currentUser();
	}

	@action
	public currentUser() {
		const token =
			localStorage.getItem("authToken") || sessionStorage.getItem("authToken");
		const userStringfy =
			localStorage.getItem("user") || sessionStorage.getItem("user");

		let user: IUser | null = null;
		try {
			user = userStringfy ? JSON.parse(userStringfy) : null;
		} catch {
			user = null;
		}

		this.user = user;
		this.token = token;

		if (this.user && this.token) {
			this.isLogged = "logged";
		} else {
			this.isLogged = "notLogged";
		}
	}

	@action
	public saveUser(user: IUser, token: string, localPersistence: boolean) {
		this.isLogged = "logged";

		this.user = user;
		this.token = token;

		if (localPersistence) {
			localStorage.setItem("authToken", token);
			localStorage.setItem("user", JSON.stringify(user));
		} else {
			sessionStorage.setItem("authToken", token);
			sessionStorage.setItem("user", JSON.stringify(user));
		}
	}

	@action
	public logout() {
		this.user = null;
		this.token = null;

		localStorage.clear();
		sessionStorage.clear();

		window.location.reload();
	}
}
